import { memo, useState, useEffect } from "react";

const Item = ({ title, initialDescription, id }) => {
    const [isExpanded, setIsExpanded] = useState(false);
    const [description] = useState(initialDescription);

    const toggleExpand = () => {
        setIsExpanded(prev => !prev);
    };

    const copyToClipboard = async () => {
        try {
            await navigator.clipboard.writeText(description);
            alert('Описание скопировано в буфер обмена!');
        } catch (err) {
            console.error('Ошибка при копировании:', err);
        }
    };

    const handleSubmit = async () => {
        const myFormData = new FormData();
        myFormData.append('text', description);

        try {
            const res = await fetch(`https://v2561009.hosted-by-vdsina.ru/api/v1/chats/ticket/${id}/sendmessage`, {
                method: "POST",
                body: myFormData,
                headers: {
                    'Accept': 'application/json',
                }
            });

            if (res.ok) {
                alert('Sell is success');
                console.log(res);
            } else {
                alert('Sell is not success');
                console.log(res);
            }
        } catch (err) {
            window.location.replace('/login');
        }
    };

    return (
        <>
            <div style={{ 
                margin: 'auto', 
                borderRadius: !isExpanded ? '10px' : '10px 10px 0 0', 
                padding: '1.2%', 
                width: '100%', 
                marginBottom: '0', 
                backgroundColor: '#13C946', 
                marginTop: '10px'
            }}>
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                    <h3 style={{ color: 'white', fontWeight: '250', marginLeft: '2%' }}>{title}</h3>
                    <div>
                        <img 
                            src={isExpanded ? '/img/open-btn-checked.png' : '/img/open-btn-unchecked.png'} 
                            alt="Свернуть/Развернуть" 
                            onClick={toggleExpand} 
                            style={{ cursor: 'pointer', marginLeft: '10px', width: "18%" }} 
                        />
                        <img 
                            src="/img/copy-btn.png" 
                            alt="Копировать" 
                            onClick={copyToClipboard} 
                            style={{ cursor: 'pointer', marginLeft: '10px', width: "18%" }} 
                        />
                        <img 
                            style={{ cursor: 'pointer', marginLeft: '10px', width: "18%" }} 
                            src="/img/input/send.png" 
                            alt="Отправить" 
                            onClick={handleSubmit}
                        />
                    </div>
                </div>
            </div>
            {isExpanded && (
                <div>
                    
                            <p style={{ width: '100%', marginTop: '0', padding: "1%", backgroundColor: '#D9D9D9', marginRight: 'auto', marginLeft: 'auto', borderRadius:'0 0 10px 10px'}}>{description}</p>
                      </div>  
                )}
        </>
    );
};
const ReqLay = memo((props) => {
    const [req, setReq] = useState([]);

    useEffect(() => {
        const fetchUser = async () => {
            try {
                const response = await fetch('https://v2561009.hosted-by-vdsina.ru/api/v1/reception/getRequisites');
                const data = await response.json();
                setReq(data);
            } catch (error) {
                console.error("Ошибка при загрузке данных:", error);
            }
        };
        fetchUser();
    }, []);

    return (
        <div className="fast-wrap">
            {req.length > 0 ? (
                req.map((item) => (
                    <Item key={item._id} title={item.title} initialDescription={item.description} id={props.data._id} />
                ))
            ) : (
                <p>Нет доступных данных.</p>
            )}
        </div>
    );
});

export default ReqLay;
