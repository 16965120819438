import { memo, useState, useEffect } from "react";
import InfoLay from "./PanelLays/InfoLay";

const PanelReception = memo((props) => {
    const [data, setData] = useState(props.data);

    useEffect(() => {
        setData(props.data);
        console.log(data);
    }, [props.data]);

    const [openedPanel, setOpenedPanel] = useState('info');

    const togglePanel = (panelName) => {
        setOpenedPanel(openedPanel === panelName ? null : panelName);
    };

    useEffect(() => {
        const form = document.getElementById('rec-pan-form');
        form.addEventListener('submit', saveArticle);

        async function saveArticle(event) {
            event.preventDefault();
            const myFormData = new FormData(form);
            console.log([...myFormData.entries()]);

            if (data && data["_id"]) { // Проверяем наличие данных
                await fetch(`https://v2561009.hosted-by-vdsina.ru/api/v1/reception/receivePay/${data["_id"]}`, {
                    method: "POST",
                    body: myFormData,
                    headers: {
                        'Accept': 'application/json',
                    }
                })
                .then((res) => {
                    if (res.ok) {
                        alert('Sell is success');
                        console.log(res);
                    } else {
                        alert('Sell is not success');
                        console.log(res);
                    }
                })
                .catch((err) => window.location.replace('/login'));
            } else {
                console.error("Data is not available");
                alert("Data is not available");
            }
        }

        return () => {
            form.removeEventListener('submit', saveArticle);
        };
    }, [data]); // Добавляем data в зависимости

    return (
        <form className="info-panel" id="rec-pan-form">
            <div className="info-panel" style={{ width: '100%' }}>
                {data && // Проверяем наличие данных
                <span className="cl-info">{data._id} | {data.first_name}</span>}
                <div className="list-3-group">
                    <span className="list-3-title">Ответственный:</span>
                </div>
                
                <input className="list-5-inp" name="rate" placeholder="Курс приёма" style={{ marginBottom: '0' }} />
                <input className="list-5-inp" name="contractor" placeholder="Контрагент" style={{ marginBottom: '0' }} />
                <input className="list-5-inp" name="amount" placeholder="Зашло USDT" style={{ marginBottom: '0' }} />

                <button className="cl-info" type="submit">Отправить</button>
                <div className="list-3-group">
                    <span className="list-3-title">Статус:</span>
                </div>
                <span className="list-7-item">Ожидает выдачу</span>
            </div>
        </form>
    );
});

export default PanelReception;
