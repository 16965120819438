import { memo, useState, useEffect } from "react";

const ExchangePanel = memo((props) => {
    const [data, setData] = useState(props.data);
    const [btns, setBtns] = useState([]);
    const [isDataUpdated, setIsDataUpdated] = useState(false); // Флаг для отслеживания обновления

    useEffect(() => {
        if (props.data !== data) {
            setData(props.data);
            setIsDataUpdated(true); // Устанавливаем флаг, когда данные обновлены
        }
    }, [props.data]); // Убираем data из зависимостей, чтобы избежать бесконечного цикла

    useEffect(() => {
        const fetchBtns = async () => {
            if (data) {
                try {
                    const response = await fetch(`https://v2561009.hosted-by-vdsina.ru/api/v1/panel/getCurrentStatuses/${data.status}`);
                    const result = await response.json();
                    setBtns(result);
                    console.log(result);
                } catch (error) {
                    console.error('Error fetching buttons:', error);
                }
            }
        };

        fetchBtns();
    }, [data]);

    if (!data) return null; // Возвращаем null, если данных нет

    return (
        <div className="fast-wrap">
            {btns.length > 0 && btns.map((item) => (
                <form key={item.status} style={{ width: '95%' }} id={item.status} onSubmit={async (event) => {
                    event.preventDefault(); // предотвратить стандартное поведение формы

                    if (!isDataUpdated) return; // Если данные еще не обновлены, ничего не делаем

                    const myFormData = new FormData(event.target);
                    console.log([...myFormData.entries()]);

                    try {
                        const res = await fetch(`https://v2561009.hosted-by-vdsina.ru/api/v1/tickets/setStatus/${data._id}`, {
                            method: "POST",
                            body: myFormData,
                            headers: {
                                'Accept': 'application/json',
                            }
                        });

                        if (res.ok) {
                            alert('Status is changed');
                            console.log(res, data["status"]);
                        } else {
                            alert('Change status error');
                            console.log(res);
                        }
                    } catch (err) {
                        alert('An error occurred while changing status.');
                        console.error(err);
                        window.location.replace('/login');
                    }
                }}>
                    <input name="status" value={item.status} style={{ display: 'none' }} />
                    <button className="copy" type='submit'>{item.title}</button>
                </form>
            ))}
        </div>
    );
});

export default ExchangePanel;
