import { useEffect, useState, memo } from "react";

const Base64Image = (props) => {
    const imageSrc = `data:image/png;base64,${props.base64String}`;
  
    return (
      <div>
        <img className="qr-img" src={imageSrc} alt="Base64 Image" />
      </div>
    );
};

const RegPage = memo(() => {

    useEffect(() => {
        const form = document.getElementById('register-1');
        form.addEventListener('submit', saveArticle);

        async function saveArticle(event) {
            event.preventDefault(); // предотвратить стандартное поведение формы
            const myFormData = new FormData(form);
            
            try {
                const response = await fetch("https://v2561009.hosted-by-vdsina.ru/api/v1/authorization/register", {
                    method: "POST",
                    body: myFormData
                });
        
                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`); // Обработка ошибок
                }
        
                const data = await response.json(); // Преобразование ответа в JSON
                console.log(data); // Выводим данные в консоль (можно удалить или заменить на нужные действия)
                setObj(data)
                // return data; // Возвращаем данные, если нужно использовать их дальше
            } catch (error) {
                console.error('Ошибка при отправке запроса:', error); // Логируем ошибку
            }
        }

        return () => {
            form.removeEventListener('submit', saveArticle);
        };
    }, []);

    const [obj, setObj] = useState(null)
    const base64Data = "iVBORw0KGgoAAAANSUhEUgAAAAUA"
        + "AAAACAIAAADnW3tDAAAACXBIWXMAAB7CAAAewgFu0HU+AAABFklEQVR42mJ8//8/AzSACwC0A2yDkDqA1DgAqQGDAEw4C2QH5gHE4YgFJgQIDGQAABQxDgEwLwSIAAAAABJRU5ErkJggg==";

    return (
        <div className="auth-lay" id="app">
            {obj === null && <form action="https://v2561009.hosted-by-vdsina.ru/api/v1/authorization/register" method="post" className="auth-card-lay" id="register-1">
                <span className="auth-card-title">Регистрация</span>
                <span className="auth-card-def-txt">Никнейм пользователя:</span>
                <input name="username"/>
                {/* <span className="auth-card-wrong-txt">Никнейм уже занят</span> */}
                <span className="auth-card-def-txt">Mail:</span>
                <input name="mail"/>
                <span className="auth-card-def-txt">Пароль:</span>
                <input name="password"/>
                <button className="login-btn-def" type="submit">Получить токен</button>
                <div className="auth-wrap-lay-2">
                    <span>Уже есть аккаунт?</span>
                    <a href="/login">Вход</a>
                </div>
            </form> }
            {obj !== null && <form action="http://178.20.44.234:8080/api/v1/authorization/register" method="post" className="auth-card-lay-qr" id="register-2">
                <span className="auth-card-title">Регистрация</span>
                <span className="auth-card-title">QR-code</span>
                <div>
                    <span className="auth-card-def-txt">Токен:</span>
                    <input value={obj.otp_secret}/>
                    <span className="auth-card-def-txt">Подтверждение пароля:</span>
                    <input name="password"/>
                    <button className="login-btn-def" type="submit">Завершить регистрацию</button>
                    <div style={{flexDirection: 'row'}} className="auth-wrap-lay-2-qr">
                        <a style={{width: "40%"}} href="/login">Google аутентификатор</a>
                        <a style={{width: "40%"}} href="/login">Microsoft аутентификатор</a>
                    </div>
                </div>
                <div>
                    <Base64Image base64String={obj.otp_qr} />
                </div>
            </form> }
        </div>
    );
});

export default RegPage;
