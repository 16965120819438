import { memo, useEffect, useState } from "react";


const List = (props) =>{
    
    const [isClosed, setIsClosed] = useState(false)
    useEffect(() => {
        const fetchUser = async () => {
            const response = await fetch('https://v2561009.hosted-by-vdsina.ru/api/v1/panel/getCourses');
            const data = await response.json();
            console.log(data);
        };
        fetchUser();
    }, []);
    return(
        <>
        <div className={isClosed ? "list-3-group-act" : "list-3-group"} onClick={() => setIsClosed(!isClosed)} >
            <span className="list-3-title">{props.country.country}</span>
            <img onClick={() => setIsClosed(!isClosed)} src="/img/collapse.png" />
        </div>

        {isClosed && (
            <div className="select-info">
                {props.country.list.map((item) => (
                    <span
                        key={item} // Добавьте ключ для каждого элемента списка
                        className={props.openedPanel === item ? "chats-item-act" : "chats-item"}
                        onClick={() => {
                            props.togglePanel(item);
                            props.setChooseCountry(props.country.country); // Обновляем выбранную страну
                        }}
                    >
                        {item}
                    </span>
                ))}
            </div>
        )}
    </>
    );
}
const CalcLay = memo((props) => {
    const [openedPanel, setOpenedPanel] = useState(null);    
    const [chooseCountry, setChooseCountry] = useState(null);                                            // null означает, что ни один компонент не открыт
    const [num, setNum] = useState('0');
    const [amount, setAmount] = useState('');
    const [wo_round, setWo_round] = useState(false);
    const [wo_delivery, setWo_delivery] = useState(false);
    const [is_fixed, setIs_fixed] = useState(false);
    const togglePanel = (panelName) => {
        setOpenedPanel(openedPanel === panelName ? null : panelName);                                       // Закрыть, если панель уже открыта
    };
    const toggleNum = (Name) => {
        setNum(num === Name ? null : Name);                                                                // Закрыть, если панель уже открыта
    };
    const [countries, setContries] = useState(null)
    useEffect(() => {
        const fetchUser = async () => {
            const response = await fetch('https://v2561009.hosted-by-vdsina.ru/api/v1/panel/getCourses');
            const data = await response.json();
            setContries(data);
        };
        fetchUser();
    }, []);
        const fetchData = async () => {
            if (openedPanel !== null && amount !== '') {
                try {
                    const response = await fetch(`https://v2561009.hosted-by-vdsina.ru/api/v1/panel/calculate/${props.data._id}`, {
                        method: "POST",
                        headers: {
                            'Content-Type': 'application/json', // Установка заголовка
                        },
                        body: JSON.stringify({                                                              // Преобразование в JSON
                            "course": openedPanel,
                            "amount": Number(amount),
                            "wo_round": wo_round,
                            "country": chooseCountry,
                            "wo_delivery": wo_delivery,
                            "is_fixed": is_fixed,
                            "discount": Number(num),
                        })
                    });

                    if (!response.ok) {
                        throw new Error(`HTTP error! status: ${response.status}`); // Обработка ошибок
                    }

                    const data = await response.json();                                                     // Преобразование ответа в JSON
                    console.log(data);                                                                      // Выводим данные в консоль (можно удалить или заменить на нужные действия)
                } catch (error) {
                    console.error('Ошибка при отправке запроса:', error);                                   // Логируем ошибку
                }
            }
            console.log({
                "course": openedPanel,
                "amount": Number(amount),
                "wo_round": wo_round,
                "country": chooseCountry,
                "wo_delivery": wo_delivery,
                "is_fixed": is_fixed,
                "discount": Number(num),
            });
        };
    return (
        <div className="fast-wrap" >
            
            {countries && openedPanel === null && countries.map((country) => (
                <List 
                    key={country.country} 
                    country={country} 
                    openedPanel={openedPanel} 
                    togglePanel={togglePanel} 
                    setChooseCountry={setChooseCountry} // Передаем функцию обновления состояния
                />
            ))}
            {
                openedPanel !== null &&
                <div className="list-3-group">
                    <span className="list-3-title" onClick={() => {
                            togglePanel(null);
                            props.setChooseCountry(null); // Обновляем выбранную страну
                        }}>{chooseCountry} | {openedPanel}</span>
                </div>
            }
                <input placeholder="Введите сумму" onChange={(e) => setAmount(e.target.value)} className="list-5-inp"/>
                <div className="list-5-check">
                    <input onClick={() => setIs_fixed(!is_fixed)} type="checkbox"/>
                    <span>Обмен с фиксированной суммой</span>
                </div>
                <div className="list-5-check">
                    <input onClick={() =>setWo_delivery(!wo_delivery)} type="checkbox"/>
                    <span>Вычесть доставку</span>
                </div>
                <div className="list-5-check">
                    <input onClick={() =>setWo_round(!wo_round)} type="checkbox"/>
                    <span>Не округлять до 100</span>
                </div>
                <div className="list-3-group">
                    <span className="list-3-title">Скидка</span>
                </div>
                <div className="select-info">
                <span className={num === "0" ? "chats-item-act" : "chats-item"} onClick={() => toggleNum("0")} >0</span>
                    <span className={num === "-0.5" ? "chats-item-act" : "chats-item"} onClick={() => toggleNum("-0.5")}>-0.5</span>
                    <span className={num === "-1.0" ? "chats-item-act" : "chats-item"} onClick={() => toggleNum("-1.0")}>-1.0</span>
                    <span className={num === "-1.5" ? "chats-item-act" : "chats-item"} onClick={() => toggleNum("-1.5")}>-1.5</span>
                    <span className={num === "-2.0" ? "chats-item-act" : "chats-item"} onClick={() => toggleNum("-2.0")}>-2.0</span>
                    <span className={num === "+0.5" ? "chats-item-act" : "chats-item"} onClick={() => toggleNum("+0.5")}>+0.5</span>
                    <span className={num === "+1.0" ? "chats-item-act" : "chats-item"} onClick={() => toggleNum("+1.0")}>+1.0</span>
                    <span className={num === "+1.5" ? "chats-item-act" : "chats-item"} onClick={() => toggleNum("+1.5")}>+1.5</span>
                    <span className={num === "+2.0" ? "chats-item-act" : "chats-item"} onClick={() => toggleNum("+2.0")}>+2.0</span>
                    <span className={num === "+3.0" ? "chats-item-act" : "chats-item"} onClick={() => toggleNum("+3.0")}>+3.0</span>
                    <span className={num === "+4.0" ? "chats-item-act" : "chats-item"} onClick={() => toggleNum("+4.0")}>+4.0</span>
                </div>
                <span className="cl-info" onClick={() => fetchData()}>Отправить</span>
        </div>
    )
});

export default CalcLay;
