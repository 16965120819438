import { memo, useState, useEffect } from "react";

const ScorePanel = memo((props) => {
    const [sc, setSc] = useState([]);
    const [searchTerm, setSearchTerm] = useState(""); // Состояние для хранения значения из инпута

    useEffect(() => {
        const fetchUser = async () => {
            const response = await fetch('https://v2561009.hosted-by-vdsina.ru/api/v1/accounts/getAccounts');
            const data = await response.json();
            setSc(data);
        };
        fetchUser();
    }, []);

    // Фильтруем элементы на основе searchTerm
    const filteredItems = sc.filter(item =>
        item.title.toLowerCase().includes(searchTerm.toLowerCase()) // Предполагается, что вы ищете по полю title
    );

    return (
        <div className="fast-wrap">
            <div className="nav-bar_search">
                <img src="/img/topNavBarPics/loop.svg" alt="" />
                <input
                    type="text"
                    placeholder="Введите данные"
                    value={searchTerm} // Устанавливаем значение инпута
                    onChange={(e) => setSearchTerm(e.target.value)} // Обновляем searchTerm при вводе
                />
            </div>
            <div className="cl-text-info-l-2">
                <span className="main-cl-text-color">Карты</span>
                <span className="main-cl-text-color">Баланс</span>
                <span className="main-cl-text-color">Лимит</span>
            </div>
            <hr />
            {filteredItems.length > 0 ? (
                filteredItems.map((item) => (
                    <div className="cl-text-info-l-2" key={item.id}> {/* Добавьте уникальный ключ для каждого элемента */}
                        <span className="second-cl-text-color">{item.title}</span>
                        <span className="main-cl-text-color">{item.balance}</span>
                        <span className="main-cl-text-color">{item.limit}</span>
                    </div>
                ))
            ) : (
                <div>Нет результатов</div> // Сообщение, если нет результатов
            )}
        </div>
    );
});

export default ScorePanel;
