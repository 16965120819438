import React, { memo, useState, useEffect, useRef} from "react"; // Не забудьте добавить useEffect
import Panel from "../FasrtnfoPanel";
// getTemplates
import { useParams } from 'react-router-dom';


const ContextMenu = ({ position, onCopy, onDelete }) => {
    return (
        <div 
            style={{
                position: 'absolute',
                top: position.y,
                left: position.x,
                backgroundColor: 'white',
                border: '1px solid #ccc',
                boxShadow: '2px 2px 5px rgba(0,0,0,0.2)',
                zIndex: 1000,
            }}
        >
            <div onClick={onCopy} style={{ padding: '10px', cursor: 'pointer' }}>Copy</div>
            <div onClick={onDelete} style={{ padding: '10px', cursor: 'pointer' }}>Delete</div>
        </div>
    );
};


const Slider = ({ openedChatSection, togglePanel, allCount }) => {
    const sliderRef = useRef(null);

    const handleWheel = (e) => {
        if (sliderRef.current) {
            sliderRef.current.scrollLeft += e.deltaY; // Прокручиваем влево/вправо
            e.preventDefault(); // Предотвращаем стандартное поведение
        }
    };

    useEffect(() => {
        const slider = sliderRef.current;
        if (slider) {
            slider.addEventListener('wheel', handleWheel, { passive: false });
        }

        return () => {
            if (slider) {
                slider.removeEventListener('wheel', handleWheel);
            }
        };
    }, []);

    return (
        <div className="slider-container">
            <div className="slider_chat" ref={sliderRef}>
                <div onClick={() => togglePanel("Own")} className={openedChatSection === 'Own' ? "chats-item-act" : "chats-item"}>Мои</div>
                <div onClick={() => togglePanel("Reception")} className={openedChatSection === 'Reception' ? "chats-item-act" : "chats-item"}>Приемка</div>
                <div onClick={() => togglePanel("Vip")} className={openedChatSection === 'Vip' ? "chats-item-act" : "chats-item"}>VIP</div>
                <div onClick={() => togglePanel("Bots")} className={openedChatSection === 'Bots' ? "chats-item-act" : "chats-item"}>Боты</div>
                <div onClick={() => togglePanel("All")} className={openedChatSection === 'All' ? "chats-item-act" : "chats-item"}>Все
                <span style={{
                        position: 'relative',
                        top: '-10px',
                        right: '-10px',
                        backgroundColor: 'red',
                        color: 'white',
                        borderRadius: '50%',
                        padding: '2px 5px',
                        fontSize: '12px'
                    }}>
                        {allCount}
                    </span>
                </div>
                <div onClick={() => togglePanel("Issue")} className={openedChatSection === 'Issue' ? "chats-item-act" : "chats-item"}>Выдача</div>
                <div onClick={() => togglePanel("History")} className={openedChatSection === 'History' ? "chats-item-act" : "chats-item"}>История</div>
            </div>
        </div>
    );
};



const ChatForm = ({ actChat }) => {
    const [messageText, setMessageText] = useState('');
    const [files, setFiles] = useState([]);
    const [isLoading, setIsLoading] = useState(false); // Состояние для отслеживания загрузки

    const handleFileChange = (event) => {
        const selectedFiles = Array.from(event.target.files);
        setFiles(selectedFiles);
    };

    const sendM = async (event) => {
        event.preventDefault();
        setIsLoading(true); // Начинаем загрузку
        const formData = new FormData();
        formData.append('text', messageText);

        files.forEach(file => {
            formData.append('files', file);
        });

        const url = files.length > 0
            ? `https://v2561009.hosted-by-vdsina.ru/api/v1/chats/ticket/${actChat}/sendfile`
            : `https://v2561009.hosted-by-vdsina.ru/api/v1/chats/ticket/${actChat}/sendmessage`;

        try {
            await fetch(url, {
                method: "POST",
                body: formData,
            });
        } catch (error) {
            console.error('Ошибка при отправке сообщения:', error);
        } finally {
            setIsLoading(false); // Завершаем загрузку
            setMessageText('');
            setFiles([]);
        }
    };

    return (
        <form autoComplete="off" className="chat-input" onSubmit={sendM}>
            <label htmlFor="file-input" style={{ position: 'relative' }}>
                <img className="addbtn" src="/img/input/add.png" alt="Добавить файл" />
                {files.length > 0 && (
                    <span style={{
                        position: 'absolute',
                        top: 0,
                        right: 0,
                        backgroundColor: 'red',
                        color: 'white',
                        borderRadius: '50%',
                        padding: '2px 5px',
                        fontSize: '12px'
                    }}>
                        {files.length}
                    </span>
                )}
            </label>
            <input 
                id="file-input" 
                type="file" 
                name="files"
                multiple 
                style={{ display: 'none' }} 
                onChange={handleFileChange} 
            />
            <input 
                value={messageText} 
                onChange={(e) => setMessageText(e.target.value)} 
                type="text" 
                placeholder="Type your message..." 
            />
            <button type="submit" id="sendBtn" disabled={isLoading}>
                <img className="sendbtn" src="/img/input/send.png" alt="Отправить" />
            </button>
        </form>
    );
};






const MainLay = memo(() => {
    const { id } = useParams(); 
    useEffect(() => {
        const fetchUser = async () => {
            const GetUser = await fetch('https://v2561009.hosted-by-vdsina.ru/api/v1/profile/getMe')
            
        } 
        fetchUser()
    }, [])

    function addDateSeparators(messages) {
        const formattedMessages = [];
        let lastDate = null;
    
        messages.forEach((message) => {
            // Проверяем, есть ли поле даты
            if (!message.date) {
                return; // Пропускаем сообщение без даты
            }
    
            // Получаем дату сообщения
            const messageDate = new Date(message.date);
            const messageDay = messageDate.toISOString().split('T')[0]; // Формат YYYY-MM-DD
    
            // Если lastDate не инициализирован или день изменился
            if (lastDate !== messageDay) {
                // Добавляем новое сообщение с датой
                formattedMessages.push({
                    type: ["command"],
                    text: `${messageDay.split('-')[2]}, ${messageDate.toLocaleString('default', { month: 'long' })}`
                });
                lastDate = messageDay; // Обновляем lastDate
            }
            formattedMessages.push(message);
        });
    
        return formattedMessages;
    }

    
    const [openedChatSection, setOpenedChatSection] = useState('Own');
    const togglePanel = (section) => {
        setOpenedChatSection(openedChatSection === section ? null : section);
    };
    const [chats, setChats] = useState([]);
    const [All, setAll] = useState(0)
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [actChat, setActChat] = useState(id ? id : null);
    const [mes, setMes] = useState(null);
    const [messageText, setMessageText] = useState('');
    const [actTic, setActTic] = useState(null)
    const changeAct = (chat_id) => {
        setActChat(actChat === chat_id ? null : chat_id);
    };
    const [delMes, setDelMes] = useState(null)

    const [file, setFile] = useState(null);
    const lib = {
        "New": "Новая",
        "Not accepted": "Не принята",
        "Accepted": "Принята",
        "Cancelled": "Отменена",
        "Calculation": "Рассчитана",
        "Agreed": "Клиент понял правила",
        "Requisites_sent": "Реквизиты отправлены",
        "Reception": "На проверке",
        "Issue": "Выдача",
        "Closed": "Завершена"
    }
    const handleFileChange = (event) => {
        const selectedFile = event.target.files[0];
        if (selectedFile) {
            setFile(selectedFile);
            // console.log(selectedFile);
        }
    };
    const [menuPosition, setMenuPosition] = useState(null);
    const [isMenuVisible, setIsMenuVisible] = useState(false);
    const [currentMessage, setCurrentMessage] = useState(null);
    const handleRightClick = (event, item, tid) => {
        event.preventDefault(); // Предотвращаем стандартное контекстное меню
        setCurrentMessage(item); // Сохраняем текущее сообщение
        setDelMes(item)
        setActTic(tid)
        setMenuPosition({ x: event.pageX, y: event.pageY });
        setIsMenuVisible(true);
    };
    const handleCopy = async () => {
        if (currentMessage) {
            await navigator.clipboard.writeText(currentMessage.text);
            
        }
        setIsMenuVisible(false);
    };
    const handleDelete = async() => {
        if (currentMessage) {
            
            const response = await fetch(`https://v2561009.hosted-by-vdsina.ru/api/v1/chats/ticket/${actTic}/del`, {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json' // Указываем тип контента
                },
                body: JSON.stringify({ 'global_id': currentMessage.global_id }) // Преобразуем объект в строку JSON
            });
            
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`); // Обработка ошибок
            }
            
            const res = await response.json(); // Получаем ответ в формате JSON
        }
        setIsMenuVisible(false);
    };
    const handleClick = () => {
        // Закрываем меню при клике вне его
        if (isMenuVisible) {
            setIsMenuVisible(false);
            setCurrentMessage(null); // Очищаем текущее сообщение
        }
    };
    useEffect(() => {
        const fetchChats = async () => {
            try {
                
                const response = await fetch(`https://v2561009.hosted-by-vdsina.ru/api/v1/chats/${openedChatSection}`);
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                const data = await response.json();
                setChats(data);
            } catch (err) {
                setError(err.message);
            } finally {
                setLoading(false);
            }
        };
        fetchChats(); // Изначально вызываем сразу
        const intervalId = setInterval(fetchChats, 1000);
        return () => clearInterval(intervalId); // Очищаем интервал при размонтировании
    }, [openedChatSection]);
    useEffect(() => {
        const fetchAll = async () => {
            try {
                const response = await fetch(`https://v2561009.hosted-by-vdsina.ru/api/v1/chats/All`);
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                const data = await response.json();
                setAll(data.length);                
            } catch (err) {
                setError(err.message);
            } finally {
                setLoading(false);
            }
        };
        fetchAll(); // Изначально вызываем сразу
        const intervalId = setInterval(fetchAll, 1000);
        return () => clearInterval(intervalId); // Очищаем интервал при размонтировании
    }, [openedChatSection]);
    useEffect(() => {
        const fetchChat = async () => {
            if (actChat != null) {
                try {
                    const response = await fetch(`https://v2561009.hosted-by-vdsina.ru/api/v1/chats/ticket/${actChat}`);
                    if (!response.ok) {
                        throw new Error('Network response was not ok');
                    }
                    const data = await response.json();
                    setMes(data);
                } catch (err) {
                    setError(err.message);
                }
            }
        };
        fetchChat();
        const intervalId = setInterval(fetchChat, 1000);
        setMessageText('')
        return () => clearInterval(intervalId);
    }, [actChat]);
    const sendM = async (event) => {
        event.preventDefault(); // предотвращаем стандартное поведение формы
        const formData = new FormData(event.target);
                try {
                
                await fetch(`https://v2561009.hosted-by-vdsina.ru/api/v1/chats/ticket/${actChat}/sendmessage`, {
                    method: "POST",
                    body: formData
                });
                setMessageText('')
            } catch (err) {
                setError(err.message);
            }
    };
    function formatDate(dateString) {
        const messageDate = new Date(dateString);
            const time = messageDate.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
            const day = String(messageDate.getDate()).padStart(2, '0');
            const month = String(messageDate.getMonth() + 1).padStart(2, '0'); // Добавляем 1 к месяцу
            const year = messageDate.getFullYear();
            return `${time}`;
    }
    function formatMessageDate(dateString) {
        const messageDate = new Date(dateString);
        const now = new Date();
        const isToday = messageDate.toDateString() === now.toDateString();
        const timeDifference = now - messageDate;
        const oneHour = 60 * 60 * 1000;
        const oneDay = 24 * oneHour;
    
        if (isToday && timeDifference < oneHour) {
            return messageDate.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
        } else if (isToday) {
            return messageDate.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
        } else if (timeDifference < oneDay) {
            return 'Вчера';
        } else {
            const day = String(messageDate.getDate()).padStart(2, '0');
            const month = String(messageDate.getMonth() + 1).padStart(2, '0'); // Добавляем 1 к месяцу
            const year = messageDate.getFullYear();
            return `${day}.${month}.${year}`;
        }
    }
    function getAvatar(tid){
        return `https://v2561009.hosted-by-vdsina.ru/api/v1/chats/ticket/${tid}/getAvatar`
    }

    function replaceNewlinesWithBr(text) {
        // Проверка на пустые данные
        if (!text || typeof text !== 'string') {
            return ''; // Возвращаем пустую строку для пустого значения
        }
    
        // Заменяем символы переноса строки на <br />
        return text.replace(/\n/g, '<br />');
    }
    const [isModalOpen, setIsModalOpen] = useState(false);

    const handleImageClick = () => {
        setIsModalOpen(true);
    };

    const handleCloseModal = () => {
        setIsModalOpen(false);
    };


    const [searchTerm, setSearchTerm] = useState(""); // Состояние для хранения значения из инпута

    // Фильтруем чаты на основе searchTerm
    const filteredChats = chats.filter(item =>
        item.first_name.toLowerCase().includes(searchTerm.toLowerCase()) || 
        item._id.toString().includes(searchTerm) // Фильтруем по ID
    );

    
    if (loading) return <div>Loading...</div>;
    if (error) return <div>Error: {error}</div>;
    
    // console.log(mes)
    return (
        <div className="main-lay">
        <div className="nav_pan">
        <img id="chat" className="panel_item" src="/img/leftNavBarPics/chat.png" onClick={() => window.location.replace('chat')}></img>
        <img className="panel_item" src="/img/leftNavBarPics/exchange.png" onClick={() => window.location.replace('exchange')}></img>
        <img className="panel_item" src="/img/leftNavBarPics/exchange.png" onClick={() => window.location.replace('table')} />
        <img className="panel_item" src="/img/leftNavBarPics/acc-def.png" onClick={() => window.location.replace('acceptence')}></img>
        <img className="panel_item" src="/img/leftNavBarPics/spam-def.png" onClick={() => window.location.replace('spam')}></img>
        <img className="panel_item" src="/img/leftNavBarPics/referal-def.png" onClick={() => window.location.replace('referals')}></img>
        {/* <img className="panel_item" src="img/leftNavBarPics/lists.png" onClick={() => window.location.replace('lists')}></img> */}
        </div>
                <div className="chats">
                    <div className="transactions">Сделки</div>
                    <div id="nav-bar_search">
                        <img src="/img/topNavBarPics/loop.svg" alt=""/>
                        <input 
                        type="text"
                        placeholder="Введите имя или ID"
                        value={searchTerm} // Устанавливаем значение инпута
                        onChange={(e) => setSearchTerm(e.target.value)} // Обновляем searchTerm при вводе
                        />
                    </div>
                    <Slider openedChatSection={openedChatSection} togglePanel={togglePanel} allCount={All}/>

                    {filteredChats.length !== 0 ? (
                filteredChats.map((item) => (
                    <div
                        className={actChat === item._id ? "chat-card-act" : "chat-card"}
                        onClick={() => changeAct(item._id)}
                        key={item._id} // Добавьте уникальный ключ для каждого элемента
                    >
                        <div className="cart-lay-1">
                            <img src={getAvatar(item._id)} />
                            <div className="cart-lay-2">
                                <div className="cart-lay-3">
                                    <span className="cart-name">{item.first_name}</span>
                                    <span className="cart-data">{item.messages[0]["date"] ? formatMessageDate(item.messages[0]["date"]) : ''}</span>
                                </div>
                                <span className="cart-mes">{item.messages[0].text}</span>
                            </div>
                        </div>
                        <div className={actChat === item._id ? "cart-lay-4-act" : "cart-lay-4"}>
                            <span>{item._id} | {item.first_name}</span>
                            <button>{lib[item.status]}</button>
                        </div>
                    </div>
                ))
            ) : (
                <div></div> // Сообщение, если нет результатов
            )}
                    
                </div>
                {actChat !== null ? <div class="chat-container">
                <div class="chat-header">
            <div className="chat-head-prof">
                {/* <img src={mes !== null && getAvatar(mes._id)}/> */}
                <span>{mes !== null && mes.first_name}</span>
            </div>
            <div className="chat-info">
                <div className="info-1">
                    <div>Обратные: ❌</div>
                    <div>Депозит: {mes !== null && mes.deposit}</div>
                    <div>Сделок: {mes !== null && mes.exchange_count}</div>
                    <div>Unix</div>
                    <div>ID: {mes !== null && mes._id} </div>
                </div>
            </div>
            
        </div>
        <div class="chat-messages">
        {mes !== null && Array.isArray(mes.messages) && addDateSeparators(mes.messages).map((item) => (
    <>
        {item.type[0] === 'command' ? (
            <div className="command-block">
                {item.text}
            </div>
        ) : (
            <div 
                onContextMenu={(e) => handleRightClick(e, item, mes.tid)} 
                className={item.from === "user" ? "message user-message" : "message admin-message"} 
                key={`message-${item.local_id}`}
            >
                <div>
                    <div className="message-content">
                        {item.from === "user" && <div className="message-username">{mes.first_name}</div>}
                        {item.type[0] === 'document' && (
                            <a 
                                href={`https://v2561009.hosted-by-vdsina.ru/api/v1/chats/ticket/${actChat}/getFile/${item.uuid}`} 
                                download 
                                className="document-link"
                            >
                                📄 {item.fileName || 'Документ'}
                            </a>
                        )}
                        {item.type[0] === 'photo' && <img className="message-photo" src={`https://v2561009.hosted-by-vdsina.ru/api/v1/chats/ticket/${actChat}/getFile/${item.uuid}`} />}
                        {item.type[0] === 'video' && <video controls className="message-photo" src={`https://v2561009.hosted-by-vdsina.ru/api/v1/chats/ticket/${actChat}/getFile/${item.uuid}`} />}
                        {item.type[0] === 'voice' && <audio controls src={`https://v2561009.hosted-by-vdsina.ru/api/v1/chats/ticket/${actChat}/getFile/${item.uuid}`}></audio>}
                        {item.global_id === 0 ? replaceNewlinesWithBr(item.text) + '(this message has been deleted)' : replaceNewlinesWithBr(item.text)}
                        <div className={item.from === "user" ? "mes-date-user" : "mes-date-admin"}>{item.date ? formatDate(item.date) : ''}</div>
                    </div>
                </div>
            </div>
        )}
    </>
))}

            {isMenuVisible && (
                <ContextMenu 
                    position={menuPosition} 
                    onCopy={handleCopy} 
                    onDelete={handleDelete} 
                />
            )}
            
            
        </div>
        <div id="endofchat"><p></p></div>

        <ChatForm actChat={actChat} />

    </div>
     :
     <div className="help-mes">
        <span>Please, select a chat</span>
        </div>}
    {actChat !== null && <Panel data={mes}/>}
        </div>
    );
});

export default MainLay;
