import { memo, useState , useEffect, useMemo } from "react";


const Table = memo((props) => {
    const [data, setData] = useState(null)
    const [Contractors, setContrators] = useState([])
    const [openedContractor, setOpenedContractor] = useState('default');
    const togglePanel = (section) => {
        setOpenedContractor(openedContractor === section ? null : section);
        console.log(section)
    };
    useEffect(() => {
        const fetchTable = async () => {
            const response = await fetch(`https://v2561009.hosted-by-vdsina.ru/api/v1/reception/getOperations/${openedContractor}`, {method: 'POST'});
            const data = await response.json();
            console.log(data)
            setData(data)
        };
        fetchTable();
    }, [openedContractor]);

    useEffect(() => {
        const fetchUser = async () => {
            const response = await fetch('https://v2561009.hosted-by-vdsina.ru/api/v1/reception/getContractors');
            const data = await response.json();
            setContrators(data);
        };
        fetchUser();
    }, []);

    useEffect(() => {
        const form = document.getElementById('addOperation');
        form.addEventListener('submit', saveArticle);

        async function saveArticle(event) {
            event.preventDefault();                                                         // предотвратить стандартное поведение формы
            const myFormData = new FormData(form);
            console.log([...myFormData.entries()]);
            await fetch(`https://v2561009.hosted-by-vdsina.ru/api/v1/reception/addOperation/${openedContractor}`, {
                method: "POST",
                body: myFormData,
                headers: {
                    'Accept': 'application/json',
                   
                }
            })
            .then((res) => {
                if (res.ok) {
                    alert('Set sucsess')
                    console.log(res)
                } else {
                    alert('Set not sucsess')
                    console.log(res)
                }
            })
            .catch((err) => window.location.replace('/login'));
        }

        return () => {
            form.removeEventListener('submit', saveArticle);
        };
    }, [openedContractor]);

    return (
        <div className="main-lay">
            <div className="nav_pan">
            <img id="chat" className="panel_item" src="img/leftNavBarPics/chat-def.png" onClick={() => window.location.replace('chat')}></img>
        <img className="panel_item" src="img/leftNavBarPics/exchange.png" onClick={() => window.location.replace('exchange')}></img>
        <img className="panel_item" src="/img/leftNavBarPics/exchange-act.png" onClick={() => window.location.replace('table')} />
        <img className="panel_item" src="img/leftNavBarPics/acc-def.png" onClick={() => window.location.replace('acceptence')}></img>
        <img className="panel_item" src="img/leftNavBarPics/spam-def.png" onClick={() => window.location.replace('spam')}></img>
        <img className="panel_item" src="img/leftNavBarPics/referal-def.png" onClick={() => window.location.replace('referals')}></img>
            </div>
            <div className="result-table-wrap">
                <div className="table-params">
                    <form className="table-params-block-1" id="addOperation">
                        <span className="table-params-block1-title">Добавить</span>
                        <div className="titles-block-1">
                            <span>Сумма:</span>
                            <span>Комментарий:</span>
                        </div>
                        <div className="table-params-block1-wrap">
                            <div className="table-params-block1-wpap-form-1">
                                
                                <input name="amount"/>
                                <button type="submit">Сохранить</button>
                            </div>
                            <div className="table-params-block1-wpap-form-2">
                                
                                <textarea name="comment"></textarea>
                            </div>
                        </div>
                    </form>
                    <div className="table-params-block-2">
                        <div className="table-sum">
                            <span className="table-sum-title">Накопленный баланс</span>
                            <span className="table-sum-result">{data !== null && data.total_amount}</span>
                        </div>
                        <div className="var-mode">
                            {Contractors !== null && Contractors.map((item) => (<span onClick={() => togglePanel(item)}>{item}</span>))}
                        </div>
                    </div>
                </div>
                
                <div className="table">
                <div className="table-title">
                            <span>ID</span>
                            <span>Дата</span>
                            <span>Сумма</span>
                            <span>Комиссия</span>
                            <span>Комментарий</span>
                        </div>
                        <hr/>
                    {data !== null && data.operations.map((item) => (
                        <>
                        <div className="table-item">
                            <span>{item._id}</span>
                            <span>{item.date}</span>
                            <span>{item.amount}</span>
                            <span>0</span>
                            <span>{item.comment}</span>
                        </div>
                        <hr/>
                        </>
                                    ))}


                    
                    
                </div>
            </div>
        </div>
    )
});

export default Table;