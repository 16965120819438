import { useState, memo, useEffect} from "react";

const Switcher = () => {
    const [isActive, setIsActive] = useState(false);

    const toggleStatus = () => {
        setIsActive(prev => !prev);
        fetch('https://v2561009.hosted-by-vdsina.ru/api/v1/profile/changeStatus', { method: 'POST' });
    };

    return (
        <div className="change_line">
            <div
                className="switcher"
                id="switcher"
                onClick={toggleStatus}
                style={{
                    backgroundColor: isActive ? '#ccc' : '#1C66D1',
                    cursor: 'pointer',
                    padding: '5px',
                    borderRadius: '20px',
                    position: 'relative',
                }}
            >
                <div
                    className="circle"
                    id="circle"
                    style={{
                        width: '20px',
                        height: '20px',
                        backgroundColor: '#fff',
                        borderRadius: '50%',
                        position: 'absolute',
                        top: '40%',
                        left: isActive ? 'calc(100% - 25px)' : '0',
                        transform: 'translateY(-50%)',
                        transition: 'left 0.3s',
                    }}
                ></div>
            </div>
            <span
                id="status"
                style={{ color: isActive ? '#000000' : '#006BD3' }}
            >
                {isActive ? 'Оффлайн' : 'Онлайн'}
            </span>
        </div>
    );
};

const TopNavBar = (props) => {
    const [prof_data, setProf_data] = useState('');
    const [pars_data, setPars_data] = useState({});
    const [rub, setRub] = useState('1');
    const [usd, setUsd] = useState('');
    const [showCourses, setShowCourses] = useState(false);
    const [selectedCourse, setSelectedCourse] = useState(null);

    const exchangeRate = 75;
    const handleRubChange = (event) => {
        const value = event.target.value;
        setRub(value);
        if (!isNaN(value) && value !== '') {
            setUsd((value / exchangeRate).toFixed(2));
        } else {
            setUsd('');
        }
    };

    const handleUsdChange = (event) => {
        const value = event.target.value;
        setUsd(value);
        if (!isNaN(value) && value !== '') {
            setRub((value * exchangeRate).toFixed(2));
        } else {
            setRub('');
        }
    };
    

    function validateInput(value) {
        if (value.nativeEvent.inputType === "insertText") {
            if (isNaN(parseInt(value.nativeEvent.data))) {
                value.target.value = value.target.value.substring(0, value.target.value.length-1);
            }
        }
    }

    useEffect(() => {
        const fetchCourses = async () => {
            const response = await fetch('https://v2561009.hosted-by-vdsina.ru/api/v1/parser');
            const data = await response.json();
            setPars_data(data);
        };
        fetchCourses();
    }, []);

    useEffect(() => {
        const fetchUser = async () => {
            const response = await fetch('https://v2561009.hosted-by-vdsina.ru/api/v1/profile/getMe');
            const data = await response.json();
            setProf_data(data);
        };
        fetchUser();
    }, []);

    const toggleCourses = () => {
        setShowCourses(prev => !prev);
    };

    const handleCourseClick = (course) => {
        setSelectedCourse(prev => (prev === course ? null : course));
    };


    function updateTime() {
        const now = new Date();
        const hours = String(now.getHours()).padStart(2, '0');
        const minutes = String(now.getMinutes()).padStart(2, '0');
        const seconds = String(now.getSeconds()).padStart(2, '0');

        const timeString = `${hours}:${minutes}`;
        window.onload = () => document.getElementById('clock').textContent = timeString;
    }

    // Обновляем время каждую секунду
    setInterval(updateTime, 1000);
    
    // Вызываем функцию один раз, чтобы сразу отобразить текущее время
    updateTime();
    const renderCourses = () => {
        return Object.entries(pars_data).flatMap(([source, courses]) =>
            Object.entries(courses).map(([course, data]) => {
                const buyRate = data.buy;
                const reverseRate = (1 / buyRate).toFixed(6);
                const isSelected = selectedCourse === course;

                return (
                    <div 
                        key={course} 
                        onClick={() => handleCourseClick(course)} 
                        style={{ 
                            cursor: 'pointer', 
                            margin: '5px 0', 
                            border: '1px solid #ccc', // Обводка для названия курса
                            padding: '5px', // Отступ внутри обводки
                            borderRadius: '16px', // Закругленные углы
                        }}
                    >
                        {isSelected ? `${course}: ${reverseRate}` : `${course}: ${buyRate}`}
                    </div>
                );
            })
        );
    };

    return (
        <div id="top_nav-bar_wrapper">
            <nav class="top_nav-bar">
                <img className="nav_img" onClick={() => window.location.replace('profile')}/>
                <div className="params_wrap2">
                    <span>Сделки</span>
                    <div className="white_wrap2">
                        <span>318</span>
                    </div>
                    <span>Рубль</span>
                    <div className="white_wrap2">
                        <input 
                        type="number"
                        id="rub"
                        value={rub}
                        onChange={handleRubChange}
                        
                        style={{width: '100%',borderRadius: '16px',color: '#c5c4c4', textAlign: 'center'}}/>
                    </div>
                    <span>USDT</span>
                    <div className="white_wrap2">
                    <input 
                    type="number"
                    id="usd"
                    value={usd}
                    onChange={handleUsdChange}
                    placeholder="Введите доллары"
                    style={{width: '100%',borderRadius: '16px',color: '#c5c4c4', textAlign: 'center'}}/>
                    </div>
                    <span>Ручной курс</span>
                    <div className="white_wrap2">
                        <span>36.17</span>
                    </div>
                    
                </div>
                <div id="clock"></div>
                <div className="courses">
                
            <button onClick={toggleCourses}>
                {showCourses ? "Скрыть курсы" : "Показать курсы"}
            </button>
            {showCourses && (
                <div style={{ 
                    position: 'absolute', // Изменено на absolute для размещения под кнопкой
                    top: '40px', // Задайте нужное значение для отступа от верхней части
                    left: '0', 
                    backgroundColor: 'white', // Фон для лучшей видимости
                    border: '1px solid #ccc', // Граница блока
                    padding: '10px',
                    zIndex: 1000 // Убедитесь, что блок находится поверх других элементов
                }}>
                    {renderCourses()}
                </div>
            )}
        
                    
                </div>
                
                <Switcher/>
                <div className="nav-prof">
                    <img src="/img/topNavBarPics/profile.svg"/>
                    <span>{prof_data.username}</span>
                </div>
            </nav>
        </div>
    );
};

export default TopNavBar;
