import { memo, useEffect, useState } from "react";

const Acceptence = memo(() => {
    const [req, setReq] = useState([]);

    useEffect(() => {
        const fetchUser = async () => {
            const response = await fetch('https://v2561009.hosted-by-vdsina.ru/api/v1/reception/getRequisites');
            const data = await response.json();
            setReq(data);
            console.log(data)
        };
        fetchUser();
    }, []);

    const colors = ['#13C946', '#7ED113', '#47C31C', '#E1623A', '#23D3C9']; // Массив цветов для label

    const getRandomColor = () => {
        return colors[Math.floor(Math.random() * colors.length)];
    };

    const Item = ({ title, initialDescription }) => {
        const [isExpanded, setIsExpanded] = useState(false);
        const [isEditing, setIsEditing] = useState(false);
        const [description, setDescription] = useState(initialDescription);
    
        const toggleExpand = () => {
            setIsExpanded(prev => !prev);
        };
    
        const handleEditClick = () => {
            setIsEditing(prev => !prev);
        };
    
        const copyToClipboard = async () => {
            try {
                await navigator.clipboard.writeText(description);
                alert('Описание скопировано в буфер обмена!');
            } catch (err) {
                console.error('Ошибка при копировании:', err);
            }
        };
    
        return (
            <>
                <div style={{ 
                    margin: 'auto', 
                    borderRadius: !isExpanded ? '10px' : '10px 10px 0 0', 
                    padding: '1.2%', 
                    width: '90%', 
                    marginBottom: '0', 
                    backgroundColor: getRandomColor(), // Используем случайный цвет
                    marginTop: '10px'
                }}>
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                        <h3 style={{ color: 'white', fontWeight: '250', marginLeft: '2%' }}>{title}</h3>
                        <div>
                            <img 
                                src={isExpanded ? '/img/open-btn-checked.png' : '/img/open-btn-unchecked.png'} 
                                alt="Свернуть/Развернуть" 
                                onClick={toggleExpand} 
                                style={{ cursor: 'pointer', marginLeft: '10px', width: "18%" }} 
                            />
                            <img 
                                src="/img/edit-btn.png" 
                                alt="Редактировать" 
                                onClick={handleEditClick} 
                                style={{ cursor: 'pointer', marginLeft: '10px', width: "18%" }} 
                            />
                            <img 
                                src="/img/copy-btn.png" 
                                alt="Копировать" 
                                onClick={copyToClipboard} 
                                style={{ cursor: 'pointer', marginLeft: '10px', width: "18%" }} 
                            />
                        </div>
                    </div>
                </div>
                {isExpanded && (
                    <div>
                        {isEditing ? (
                            <input 
                                type="text" 
                                value={description} 
                                onChange={(e) => setDescription(e.target.value)} 
                                onBlur={handleEditClick} // Закрываем редактирование при потере фокуса
                                style={{ width: '90.4%', marginTop: '0', padding: "1%" }}
                                />
                            ) : (
                                <p style={{ width: '90%', marginTop: '0', padding: "1%", backgroundColor: '#D9D9D9', marginRight: 'auto', marginLeft: 'auto', borderRadius:'0 0 10px 10px'}}>{description}</p>
                            )}
                        </div>
                    )}
                </>
            );
        };
    
        return (
<div className="main-lay">
            <div className="nav_pan">
            <img id="chat" className="panel_item" src="img/leftNavBarPics/chat-def.png" onClick={() => window.location.replace('chat')}></img>
        <img className="panel_item" src="img/leftNavBarPics/exchange.png" onClick={() => window.location.replace('exchange')}></img>
        <img className="panel_item" src="/img/leftNavBarPics/exchange.png" onClick={() => window.location.replace('table')} />
        <img className="panel_item" src="img/leftNavBarPics/acc-act.png" onClick={() => window.location.replace('acceptence')}></img>
        <img className="panel_item" src="img/leftNavBarPics/spam-def.png" onClick={() => window.location.replace('spam')}></img>
        <img className="panel_item" src="img/leftNavBarPics/referal-def.png" onClick={() => window.location.replace('referals')}></img>
        </div>
        <div className="rules-wrap">
        <div className="rules-text">
                    <span className="rule-header">Правила заполнения</span>
                    <p className="rule-text">В каждом поле укажите только одни реквизиты! Помните, что реквизиты будут отправляться клиенту. 
Номер карты необходимо 
заполнять без пробелов!</p>
                    <p className="rule-desc">Пожалуйста, не указывайте лишнюю информацию. Только номер для получения и ФИО. Вся остальная информация о сроке действия реквизитов будет подставлена автоматически!</p>
                </div>

            <div className="acceptence" id="app">

                
                
<div className="hor-flex-acc">
            {req.map((item, index) => (
                item._id % 2 === 1 && <>
                <Item key={index} title={item.title} initialDescription={item.description} />
                </>
            ))}
</div>
<div className="hor-flex-acc">
            {req.map((item, index) => (
                item._id % 2 === 0 && <>
                <Item key={index} title={item.title} initialDescription={item.description} />
                </>
            ))}
</div>
                </div>
            </div>
        </div>
    );
});

export default Acceptence;