import { memo, useState, useEffect } from "react";



const GetLay = memo((props) => {

    const handleSubmit = async (text) => {
        const myFormData = new FormData();
        myFormData.append('text', text);
    
        try {
            const res = await fetch(`https://v2561009.hosted-by-vdsina.ru/api/v1/chats/ticket/${props.data._id}/sendmessage`, {
                method: "POST",
                body: myFormData,
                headers: {
                    'Accept': 'application/json',
                }
            });
    
            if (res.ok) {
                alert('Sell is success');
                console.log(res);
            } else {
                alert('Sell is not success');
                console.log(res);
            }
        } catch (err) {
            window.location.replace('/login');
        }
    };


    const [data, setData] = useState(null);
    const [amount, setAmount] = useState(''); // Состояние для суммы
    const [selectedAccount, setSelectedAccount] = useState(''); // Состояние для выбранного счета
    const [errorMessage, setErrorMessage] = useState(''); // Состояние для сообщения об ошибке
    const [serverError, setServerError] = useState(''); // Состояние для ошибки сервера

    useEffect(() => {
        const fetchTable = async () => {
            const response = await fetch(`https://v2561009.hosted-by-vdsina.ru/api/v1/issue/GetIssueData/${props.data._id}`);
            const data = await response.json();
            console.log(data);
            setData(data);
        };
        fetchTable();
    }, [props.data._id]);

    const handleCopyToAmount = () => {
        if (data) {
            setAmount(data.need_pay); // Копируем значение need_pay в состояние
        }
    };

    const handleAddTen = () => {
        setAmount(prevAmount => (parseInt(prevAmount) || 0) + 10); // Прибавляем 10 к текущему значению
    };

    const handleSave = async () => {
        // Проверка на наличие данных
        if (!amount || !selectedAccount) {
            setErrorMessage('Пожалуйста, укажите сумму и выберите счет.');
            return;
        }

        setErrorMessage(''); // Очистка сообщения об ошибке
        setServerError(''); // Очистка сообщения об ошибке сервера

        const formData = new FormData();
        formData.append('amount', amount);
        formData.append('account', selectedAccount);

        console.log('Отправляемые данные:', {
            amount: amount,
            account: selectedAccount,
        });

        try {
            const response = await fetch(`https://v2561009.hosted-by-vdsina.ru/api/v1/issue/SendPay/${props.data._id}`, {
                method: 'POST',
                body: formData
            });

            if (!response.ok) { // Проверка на успешный ответ
                const errorData = await response.json();
                throw new Error(errorData.message || 'Ошибка сервера');
            }

            const result = await response.json();
            console.log('Ответ:', response);
            console.log('Результат:', result);
            alert('Данные успешно отправлены!');
        } catch (error) {
            console.error('Ошибка:', error);
            setServerError('Произошла ошибка при отправке данных: ' + error.message); // Установка сообщения об ошибке сервера
        }
    };

    return (
        <div className="fast-wrap">
            <div className="list-3-group" style={{backgroundColor: 'unset'}}>
                <span className="list-3-title" style={{backgroundColor: 'unset'}}>Осталось выдать:</span>
            </div>
            <span className="cl-info">{data === null ? 'Не оплачено' : data.need_pay}</span>
            
            <select onChange={(e) => setSelectedAccount(e.target.value)} value={selectedAccount}>
            <option value="">Выберите счет</option>
                {data?.accounts && data.accounts.map((item) => (
                    <option key={item.id} value={item.title}>{item.title}</option>
                ))}
            </select>
            
            <span className="list-7-item" onClick={handleCopyToAmount} style={{ cursor: 'pointer' }}>
                Копировать в сумму
            </span>
            
            <div className="list-3-group" style={{backgroundColor: 'unset'}}>
                <span className="list-3-title" style={{backgroundColor: 'unset'}}>Сумма:</span>
            </div>

            <div className="list-7-inp">
                <input 
                    placeholder="Введите сумму" 
                    value={amount} 
                    readOnly 
                />
                <span 
                    style={{cursor: 'pointer' }} 
                    onClick={handleAddTen}
                >
                    +10
                </span>
            </div>

            {errorMessage && <div style={{ color: 'red' }}>{errorMessage}</div>} {/* Отображение сообщения об ошибке */}
            {serverError && <div style={{ color: 'red' }}>{serverError}</div>} {/* Отображение сообщения об ошибке сервера */}

            <button type="submit" className="list-7-item" onClick={handleSave} style={{cursor: 'pointer', width: '93%', marginBottom: '5%' }} >
                Сохранить
            </button>

            <span className="list-7-item" onClick={() => handleSubmit("Нажмите Confirm")}>Нажмите Confirm</span>
            <span className="list-7-item" onClick={() =>handleSubmit("Ожидаю QR код")}>Ожидаю QR код</span>
            <span className="list-7-item" onClick={() =>handleSubmit("Пришлите новый QR код, пожалуйста")}>Пришлите новый QR код, пожалуйста</span>
            <span className="list-7-item" onClick={() =>handleSubmit("Я выплачиваю")}>Я выплачиваю</span>
            <span className="list-7-item" onClick={() =>handleSubmit("У какого Вы ATM?")}>У какого Вы ATM?</span>
            
            <div className="cl-text-info-l-7">
                <span className="main-cl-text-color">ID</span>
                <span className="main-cl-text-color">Карта</span>
                <span className="main-cl-text-color">Сумма</span>
            </div>
            <div className="cl-text-info-l-7">
            {data?.operations && data.operations.map((item) => (
                <>
                    <span className="second-cl-text-color">{item._id}</span>
                    <span className="second-cl-text-color">{item.from}</span>
                    <span className="second-cl-text-color">{item.amount}</span>
                </>
                    
                ))}
            </div>
        </div>
    );
});

export default GetLay;
