import './App.css';
import NavBar from "./components/navBar";
import { useState } from "react";
import MainLay from './components/pages/MainLay';
import ExchangeLay from './components/pages/ExchangeLay';
import Profile from './components/pages/ProfLay';
import SignIn from './components/pages/SignIn';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import RegPage from './components/auth_pages/RegPage';
import SecondRegPage from './components/auth_pages/SecondRegPage';
import ThirdRegPage from './components/auth_pages/ThirdRegPage';
import AcceptPage from './components/auth_pages/AcceptPage';
import NextAcceptPage from './components/auth_pages/NextAcceptPage';
import Acceptence from './components/pages/Acceptance';
import Referals from './components/pages/Referals';
import Spamer from './components/pages/Spamer';
import Table from './components/pages/SummaryTable';
import ChatReception from './components/pages/Chat-reception';

function App() {
  const [dealsDisplay, setDealsDisplayOpenned] = useState(true);
  const [operationsDisplay, setOperationsDisplayOpenned] = useState(false);
  
  const DealsLay = () => {
      setDealsDisplayOpenned(true);
      setOperationsDisplayOpenned(false);
  };
  
  const OperationsLay = () => {
      setDealsDisplayOpenned(false);
      setOperationsDisplayOpenned(true);
  };

  return (
    <div className="App">
      {document.cookie ? <NavBar/> : null}
      <BrowserRouter>
        <Routes>
          <Route path="login" element={<SignIn />} />
          <Route path="reception" element={<ChatReception />} />
          <Route path="reg" element={<RegPage />} />
          <Route path="nextreg" element={<SecondRegPage />} />
          <Route path="lastnextreg" element={<ThirdRegPage />} />
          <Route path="acceptlog" element={<AcceptPage />} />
          <Route path="nextacceptlog" element={<NextAcceptPage />} />
          <Route path="profile" element={<Profile />} />
          {/* Изменено: добавлен параметр ID для чата */}
          <Route path="chat/:id" element={<MainLay />} />
          <Route path="chat" element={<MainLay />} />
          <Route path="exchange" element={<ExchangeLay />} />
          <Route path="acceptence" element={<Acceptence />} />
          <Route path="spam" element={<Spamer />} />
          <Route path="referals" element={<Referals />} />
          <Route path="lists" element={<Acceptence />} />
          <Route path="table" element={<Table />}/>
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
