import { memo, useState, useEffect } from "react";
const ExchangeLay = memo((props) => {
    const [req, setReq] = useState([]);
    const [marks, setMarks] = useState([]);
    const [searchId, setSearchId] = useState('');
    const [searchDate, setSearchDate] = useState('');
    const [searchFromTo, setSearchFromTo] = useState('');
    const [searchAmount, setSearchAmount] = useState('');
    const [searchComment, setSearchComment] = useState('');
    const [searchSdelka, setSearchSdelka] = useState('');

    // Функция для фильтрации данных
    const filteredMarks = marks.filter(mark => {
        return (
            mark._id.toString().includes(searchId) &&
            mark.date.includes(searchDate) &&
            `${mark.from} ${mark.to}`.includes(searchFromTo) &&
            mark.amount.toString().includes(searchAmount) &&
            mark.comment.includes(searchComment) &&
            mark._id.toString().includes(searchSdelka)
        );
    });
    useEffect(() => {
        const form = document.getElementById('sell');
        form.addEventListener('submit', saveArticle);

        async function saveArticle(event) {
            event.preventDefault(); // предотвратить стандартное поведение формы
            const myFormData = new FormData(form);
            console.log([...myFormData.entries()]);
            await fetch("https://v2561009.hosted-by-vdsina.ru/api/v1/operations/addTransfer", {
                method: "POST",
                body: myFormData,
                headers: {
                    'Accept': 'application/json',
                    // 'Content-Type': 'multipart/form-data' // Не добавляйте этот заголовок, если используете FormData
                }
            })
            .then((res) => {
                if (res.ok) {
                    alert('Sell is sucsess')
                    console.log(res)
                } else {
                    alert('Sell is not sucsess')
                    console.log(res)
                }
            })
            .catch((err) => window.location.replace('/login'));
        }

        return () => {
            form.removeEventListener('submit', saveArticle);
        };
    }, []);


    useEffect(() => {
        const form = document.getElementById('marker');
        form.addEventListener('submit', saveArticle);

        async function saveArticle(event) {
            event.preventDefault(); // предотвратить стандартное поведение формы
            const myFormData = new FormData(form);
            console.log([...myFormData.entries()]);
            await fetch("https://v2561009.hosted-by-vdsina.ru/api/v1/operations/addSell ", {
                method: "POST",
                body: myFormData,
                headers: {
                    'Accept': 'application/json',
                    // 'Content-Type': 'multipart/form-data' // Не добавляйте этот заголовок, если используете FormData
                }
            })
            .then((res) => {
                if (res.ok) {
                    alert('Sell is sucsess')
                    console.log(res)
                } else {
                    alert('Sell is not sucsess')
                    console.log(res)
                }
            })
            .catch((err) => window.location.replace('/login'));
        }

        return () => {
            form.removeEventListener('submit', saveArticle);
        };
    }, []);


    useEffect(() => {
        const fetchUser = async () => {
            const response = await fetch('https://v2561009.hosted-by-vdsina.ru/api/v1/reception/getRequisites');
            const data = await response.json();
            setReq(data);
        };
        fetchUser();
    }, []);
    useEffect(() => {
        const fetchUser = async () => {
            const response = await fetch('https://v2561009.hosted-by-vdsina.ru/api/v1/operations/getOperations');
            const data = await response.json();
            console.log(data)
            setMarks(data)
        };
        fetchUser();
    }, []);
    return (
        <div className="main-lay">
            <div className="nav_pan">
            <img id="chat" className="panel_item" src="img/leftNavBarPics/chat-def.png" onClick={() => window.location.replace('chat')}></img>
        <img className="panel_item" src="img/leftNavBarPics/exchange-act.png" onClick={() => window.location.replace('exchange')}></img>
        <img className="panel_item" src="/img/leftNavBarPics/exchange.png" onClick={() => window.location.replace('table')} />
        <img className="panel_item" src="img/leftNavBarPics/acc-def.png" onClick={() => window.location.replace('acceptence')}></img>
        <img className="panel_item" src="img/leftNavBarPics/spam-def.png" onClick={() => window.location.replace('spam')}></img>
        <img className="panel_item" src="img/leftNavBarPics/referal-def.png" onClick={() => window.location.replace('referals')}></img>
        </div>
        <div className="result-table-wrap" style={{backgroundColor: '#C7C6D1', overflowY: 'auto'}}>
            <div className="exch-lay-wrap" style={{height: '93%'}}>
                <form className="exch-card-wrap" id="sell"> 
                    <span className="exch-card-title" >Внутренний перевод</span>
                    <span className="exch-card-it">Откуда</span>
                    <select name="from_">
                    {req.length > 0 && req.map((item) => (
                        <option value={item.title} key={item.id} style={item._id % 2 === 0 ? {color:" #3E3E3E", backgroundColor: "#C5C4C4"}: {color:" #3E3E3E", backgroundColor: "#fff"}}>{item.title}</option>
                        
                    ))}
                    </select>
                    <span className="exch-card-it">Куда</span>
                    <select name="to">
                    {req.length > 0 && req.map((item) => (
                        <option value={item.title} key={item.id}>{item.title}</option>
                        
                    ))}
                    </select>
                    <span className="exch-card-it">Сумма</span>
                    <input name="amount" placeholder="Введите сумму" />
                    <span className="exch-card-it">Комментарий:</span>
                    <textarea name="comment"></textarea>
                    <button type="submit">Добавить</button>
                </form>
                <form className="exch-card-wrap" id="marker">  
                    <span className="exch-card-title">Продажа маркера</span>
                    <span className="exch-card-it">Маркер</span>
                    <input name="marker" placeholder="Введите сумму" />
                    <span className="exch-card-it">Курс</span>
                    <input name="rate" placeholder="Введите сумму" />
                    <span className="exch-card-it">Сумма</span>
                    <input name="amount" placeholder="Введите сумму" />
                    <span className="exch-card-it">Карта:</span>
                    <select name="card">
                    {req.length > 0 && req.map((item) => (
                        <option value={item.title} key={item.id}>{item.title}</option>
                        
                    ))}
                    </select>
                    <span className="exch-card-it">Комментарий:</span>
                    <textarea name="comment"></textarea>
                    <button type="submit">Добавить</button>
                </form>
            </div>
            <div className="exch-filt"> 
                    <div className="exch-filt-wrap"> 
                        <span className="exch-filt-title">Id</span>
                        <span className="exch-filt-title">Дата</span>
                        <span className="exch-filt-title">Счёт</span>
                        <span className="exch-filt-title">Сумма</span>
                        <span className="exch-filt-title">Комментарий</span>
                        <span className="exch-filt-title">Сделка</span>
                    </div>
                    <div className="exch-filt-wrap" id="search"> 
                <input 
                    id="id" 
                    placeholder="Поиск по ID" 
                    value={searchId}
                    onChange={(e) => setSearchId(e.target.value)}
                />
                <input 
                    id="date" 
                    placeholder="Поиск по дате" 
                    value={searchDate}
                    onChange={(e) => setSearchDate(e.target.value)}
                />
                <input 
                    id="from-to" 
                    placeholder="Поиск от-до" 
                    value={searchFromTo}
                    onChange={(e) => setSearchFromTo(e.target.value)}
                />
                <input 
                    id="amount" 
                    placeholder="Поиск по сумме" 
                    value={searchAmount}
                    onChange={(e) => setSearchAmount(e.target.value)}
                />
                <input 
                    id="comment" 
                    placeholder="Поиск по комментарию" 
                    value={searchComment}
                    onChange={(e) => setSearchComment(e.target.value)}
                />
                <input 
                    id="sdelka" 
                    placeholder="Поиск по сделке" 
                    value={searchSdelka}
                    onChange={(e) => setSearchSdelka(e.target.value)}
                />
            </div>
            
            <div style={{ overflowY: 'auto' }}> 
                
                {filteredMarks.map((mark) => (
                    <>
                    <div className="exch-filt-wrap" key={mark._id}> 
                        <span className="exch-filt-txt">{mark._id}</span>
                        <span className="exch-filt-txt">{mark.date}</span>
                        <span className="exch-filt-txt">{mark.from} {mark.to}</span>
                        <span className="exch-filt-txt">{mark.amount}</span>
                        <span className="exch-filt-txt">{mark.comment}</span>
                        <span className="exch-filt-txt">Сделка #{mark._id}</span>
                        
                    </div>
                    <hr />
                    </>
                ))}
            </div>
                </div>
            </div>
        </div>
    )
});

export default ExchangeLay;
