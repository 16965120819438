import { memo, useState, useEffect } from "react";

const Profile = memo((props) => {
    useEffect(() => {
        const form = document.getElementById('inf-2');
        form.addEventListener('submit', saveArticle);

        async function saveArticle(event) {
            event.preventDefault();                                                         // предотвратить стандартное поведение формы
            const myFormData = new FormData(form);
            console.log([...myFormData.entries()]);
            await fetch("https://v2561009.hosted-by-vdsina.ru/api/v1/profile/setContacts", {
                method: "POST",
                body: myFormData,
                headers: {
                    'Accept': 'application/json',
                                                                                            // 'Content-Type': 'multipart/form-data' // Не добавляйте этот заголовок, если используете FormData
                }
            })
            .then((res) => {
                if (res.ok) {
                    alert('Set sucsess')
                    console.log(res)
                } else {
                    alert('Set not sucsess')
                    console.log(res)
                }
            })
            .catch((err) => window.location.replace('/login'));
        }

        return () => {
            form.removeEventListener('submit', saveArticle);
        };
    }, []);



    useEffect(() => {
        const form = document.getElementById('inf-1');
        form.addEventListener('submit', saveArticle);

        async function saveArticle(event) {
            event.preventDefault();                                                         // предотвратить стандартное поведение формы
            const myFormData = new FormData(form);
            console.log([...myFormData.entries()]);
            await fetch("https://v2561009.hosted-by-vdsina.ru/api/v1/profile/setInfo", {
                method: "POST",
                body: myFormData,
                headers: {
                    'Accept': 'application/json',
                                                                                            // 'Content-Type': 'multipart/form-data' // Не добавляйте этот заголовок, если используете FormData
                }
            })
            .then((res) => {
                if (res.ok) {
                    alert('Set sucsess')
                    console.log(res)
                } else {
                    alert('Set not sucsess')
                    console.log(res)
                }
            })
            .catch((err) => window.location.replace('/login'));
        }

        return () => {
            form.removeEventListener('submit', saveArticle);
        };
    }, []);




    function hasCookies() {
                                                                                                // document.cookie возвращает строку с куками или пустую строку, если их нет
        return document.cookie.length > 0;
    }
    const [prof_data, setProf_data] = useState('');
    useEffect(() => {
        const fetchUser = async () => {
            const response = await fetch('https://v2561009.hosted-by-vdsina.ru/api/v1/profile/getMe');
            const data = await response.json();
            setProf_data(data);
            console.log(data)
        };
        fetchUser();
    }, []);
    return (
        <div className="main-lay">
            <div className="nav_pan">
            <img id="chat" className="panel_item" src="img/leftNavBarPics/chat-def.png" onClick={() => window.location.replace('chat')}></img>
        <img className="panel_item" src="img/leftNavBarPics/exchange.png" onClick={() => window.location.replace('exchange')}></img>
        <img className="panel_item" src="/img/leftNavBarPics/exchange.png" onClick={() => window.location.replace('table')} />
        <img className="panel_item" src="img/leftNavBarPics/acc-def.png" onClick={() => window.location.replace('acceptence')}></img>
        <img className="panel_item" src="img/leftNavBarPics/spam-def.png" onClick={() => window.location.replace('spam')}></img>
        <img className="panel_item" src="img/leftNavBarPics/referal-def.png" onClick={() => window.location.replace('referals')}></img>
        </div>
            <div className="exch-lay-wrap" style={{height: '100%'}}>
                <form className="prof_card_wrap" id="inf-1">
                    <span className="exch-filt-title">Общая информация</span>
                    <div className="prof-lay-data">
                        <img></img>
                        <div className="prof-data-params">
                            <span id="nick">Никнейм пользователя:</span>
                            <input name="username" placeholder={prof_data.username}/>
                            <span id="nick-valid">Никнейм свободен</span>
                            <a href="">Сменить аватар</a>
                        </div>
                    </div>
                    <span>Имя пользователя:</span>
                    <input name="name" placeholder={prof_data.name}/>
                    <span>Фамилия пользователя:</span>
                    <input name="surname" placeholder={prof_data.surname}/>
                    <button type="submit">Сохранить изменения</button>
                </form>
                <form className="prof_card_wrap" id="inf-2">
                    <span className="exch-filt-title">Контакты</span>
                    <span>Telegram:</span>
                    <input name="telegram" placeholder={prof_data.telegram}/>
                    <span>WhatsApp:</span>
                    <input name="whatsapp" placeholder={prof_data.whatsapp}/>
                    <span>Email:</span>
                    <input name="mail" placeholder={prof_data.mail}/>
                    <button type="submit" id="second-prof-btn">Сохранить изменения</button>
                </form>
                <div className="prof_card_wrap">
                    <span className="exch-filt-title">Должность</span>
                    <span id="rang">Telegram: {prof_data.telegram}</span>
                    <p>Должностные инструкции: А я не знаю, что тут писать, но что-то точно должно быть для наполнения блока</p>
                </div>
            </div>
            
        </div>
    )
});

export default Profile;
